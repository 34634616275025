@import "vars";
@import "mixins";
@import "../assets/styles/helpers";
@import "layouts/main";
@import "common/general";
@import "ui/index.scss";
@import "modules/index.scss";
@import "landing/index";
@import "../assets/styles/main";

body {
  background: $main_bg;
  overflow-x: auto;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

body.modal-opened {
  overflow: hidden;
}

.landing-pages {
  font-family: $mainSansSerif;
  background: $dark;
  color: #fff;
  
  h1,h2,h3,h4,h5,h6 {
    color: #fff;
    font-family: $mainSansSerifBold;
    text-transform: uppercase;
  }
  .ant-typography {
    color: #fff;
  }

  form {
    input,
    textarea {
      margin: 20px auto 0;
      padding: 14px 24px;
      width: 100%;
      background: $defaultWhite;
      height: 45px;
      border: none;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 10px;
      color: $titleBlack;
      font-size: 14px;
      line-height: 16px;
      box-shadow: 0 15px 30px $slideBoxShadow;
      resize: none;

      &::placeholder {
        display: inline;
        vertical-align: top;
      }

      &:focus,
      :active {
        outline: none;
        padding: 12px 22px;
        border: 2px solid $borderBlue;
      }
    }
  }
}
.contact-section-form {
  width: 100%;
  max-width: 100%;
  position: relative;
  z-index: 2;
  background: linear-gradient(180deg, #2A3C4D 0%, #050B11 100%);
  border-radius: 12px;
  padding: 30px;
  color: #fff;

  &__info {
    h2 {
      text-align: left;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      line-height: 1.5;
      margin: 0;
      margin-bottom: 20px;
    }
  }

  textarea.ant-input {
    height: 225px;
  }

  .ant-input {
    margin: 0;
    height: 50px;
    border-radius: 30px;
    font-family: $titleFont;
    //color: #091D32;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    box-shadow: none;
    background: #fff !important;
    color: #000 !important;
    &::placeholder {
      color: #091D32 !important;
    }
  }

  .ant-form-item-explain {
    color: #fff;
    padding: 0;
    font-size: 12px;
    margin-top: 5px;
  }

  .btn {
    min-width: 150px;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 30px;
  }
}
.ant-input-affix-wrapper {
  border-radius: 12px;
  background: #fff !important;
  border: none !important;
  border-bottom: 1px solid #fff !important;
  box-shadow: none !important;

  .ant-input {
    border: none !important;
  }

}
.ant-input {
  background: #fff !important;
  outline: none !important;
  color: #000 !important;
  border-radius: 12px !important;
}