.news-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;

  @include media('<1200px') {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media('<768px') {
    grid-template-columns: repeat(1, 1fr);
  }
}

.news-preview,
.news-content {
  background: $darkBox;
  border-radius: 6px;
  overflow: hidden;
  color: #fff;
  position: relative;

  h2 {
    font-size: 16px !important;
    line-height: 1.4 !important;
    text-align: left !important;
    color: #fff;
    font-style: normal;
    margin-bottom: 10px !important;
    text-transform: none !important;
    font-weight: bold;
    a {
      color: #fff;

      &:hover {
        color: $secondaryColor;
      }
    }
  }
  &__date {
    font-size: 10px;
    line-height: 20px;
    background: rgba(0,0,0, .7);
    display: inline-block;
    position: absolute;
    left: 10px;
    top: 10px;
    padding: 0 5px;
    pointer-events: none;

  }
  &__content {
    padding: 10px;
    font-size: 13px;
    line-height: 1.3;
    color: rgba(250, 250, 250, .5);
    font-style: italic;
    p {
      margin: 0;
    }
  }
  &__img {
    display: flex;
    height: 220px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all .2s ease;
    }
    &:hover {
      img {
        transform: scale(1.075);
      }
    }
  }
}

.news-content {
  &__img {
    height: 430px;
    pointer-events: none;
    user-select: none;

    @include media('<568px') {
      height: auto;
    }
    &:hover {
      img {
        transform: none;
      }
    }
  }

  h2 {
    font-size: 24px !important;

    @include media('<768px') {
      font-size: 18px !important;

    }
  }
}