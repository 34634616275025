.about-us {
  background: linear-gradient(180deg, #2A3C4D 0%, #050B11 100%);
  border-radius: 12px;
  position: relative;
  min-height: 400px;
  overflow: hidden;
  padding: 50px;
  margin: 50px 0;

  @include media('<1200px') {
    padding: 20px 10px;
  }
  h2 {
    font-size: 36px;
    margin-bottom:0;
    font-weight: bold;

    @include media('<1200px') {
      font-size: 24px;
    }
  }

  &__img {
    width: 450px;
    height: 525px;
    position: absolute;
    bottom: -150px;
    right: 100px;
    pointer-events: none;
    cursor: default;
    
    @include media('<1200px') {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .btn {
    min-width: 200px;
  }
}
.about-list {
  list-style: none;
  font-size: 18px;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  margin-top: 35px;
  margin-bottom: 50px;

  @include media("<568px") {
    font-size: 16px;
  }
  li {
    +li {
      margin-top: 5px;
    }
    position: relative;
    padding-left: 25px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 8px;
      width: 10px;
      height: 10px;
      background-color: $primaryColor;
      border-radius: 50%;
    }
  }
}